import React, { Component, useState } from "react";
import Nav from "../nav/nav";
import Carousel from "../home/carousel/carousel";
import About from "./about/about";
import Services from "./services/services";
import WhyChoose from "./whychoose/whychoose";
import Testimonial from "./testimonial/testimonial";
import Remedies from "./remedies/remedies";
import Footer from "../footer/footer";
import Modal from "react-bootstrap/Modal";
import popup_image from "../../src/img/book-cover.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import { useParams } from "react-router-dom";
import PostApiCall from "../API/POSTAPI";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ConsultForm from "./ConsultForm/ConsultForm";
import { Helmet } from "react-helmet";
import QRCodeComponent from "../Qr/QrimageGenrate";

function Home() {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  const [modalShow, SetModalShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  window.onload = () => {
    if (
      localStorage.getItem("popup_open") == null ||
      localStorage.getItem("popup_open") == undefined ||
      localStorage.getItem("popup_open") == "yes"
    ) {
      SetModalShow(true);
      localStorage.setItem("popup_open", "yes");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Blessings Astrology - Astrologer | Jyotish Shastra | Dr. Surabhi
          Bhatnagar
        </title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          name="keywords"
          content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, astrological services"
        />

        <link rel="canonical" href="https://www.blessingsastrology.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Blessings Astrology - Astrologer | Jyotish Shastra | Dr. Surabhi Bhatnagar"
        />
        <meta
          property="og:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta property="og:url" content="https://www.blessingsastrology.com/" />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/2.861a0cfd6c9126e4c49b.png"
        />
        <meta
          name="twitter:title"
          content="Blessings Astrology - Astrologer | Jyotish Shastra | Dr. Surabhi Bhatnagar"
        />
        <meta
          name="twitter:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/2.861a0cfd6c9126e4c49b.png"
        />
      </Helmet>
      <Nav />
      <Carousel />
      <About />
      <Services />
      <WhyChoose />
      <Remedies />
      <Testimonial />
      {/* <QRCodeComponent /> */}
      <Modal
        show={modalShow}
        onHide={() => SetModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="sm"
      >
        <Modal.Body className="p-0">
          {/* <div className="add-modal">
              <Modal.Header closeButton></Modal.Header>

              <img src={popup_image} className="w-100 popup" />
              <Button
                className="popup-btn"
                onClick={() => {
                  localStorage.setItem("popup_open", "no");
                  SetModalShow(false);
                  handleShow(true);
                }}>
                Consult Now
              </Button>
            </div> */}
          <div className="add-modal">
            <Modal.Header closeButton></Modal.Header>

            <img src={popup_image} className="w-100 popup" />
            <a
              href="https://amzn.in/d/fnIHnV8"
              className="popup-btn"
              target="_blank"
            >
              Buy Now
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <div></div>
      {/* *** Consult button **** */}
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex align-items-center"
      >
        <Modal.Header closeButton style={{ zIndex: "999" }}></Modal.Header>
        <Modal.Body>
          <ConsultForm setShow={setShow} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Home;
