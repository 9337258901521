import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Autoplay, Pagination } from "swiper";

import "./horoscopepage.css";

import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
import aries from "../img/horoscope/aries.png";
import taurus from "../img/horoscope/taurus.png";
import gemini from "../img/horoscope/gemini.png";
import Cancer from "../img/horoscope/cancer.png";
import leo from "../img/horoscope/leo.png";
import virgo from "../img/horoscope/virgo.png";
import libra from "../img/horoscope/libra.png";
import scorpio from "../img/horoscope/Scorpio.png";
import Sagittarius from "../img/horoscope/Saggitarius.png";
import capricorn from "../img/horoscope/Capricorn.png";
import aquarius from "../img/horoscope/Aquarius.png";
import pisces from "../img/horoscope/Pisces.png";
import s1 from "../img/service/1.png";
import s2 from "../img/service/2.png";
import s3 from "../img/service/3.png";
import s4 from "../img/service/4.png";
import s5 from "../img/service/pa.png";
import s6 from "../img/service/nu.png";
import s7 from "../img/service/re.png";

export default function horoscopepage() {
  return (
    <>
      <Helmet>
        <title>Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar</title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          name="keywords"
          content="Horoscope Prediction, Aries horoscope, Taurus horoscope, taurus weekly horoscope, Gemini horoscope, Cancer horoscope, weekly Leo horoscope, Virgo horoscope, virgo weekly horoscope, weekly Libra horoscope"
        />

        <link
          rel="canonical"
          href="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          property="og:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          property="og:url"
          content="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta
          property="og:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
        <meta
          name="twitter:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          name="twitter:description"
          content="Predict your weekly Horoscope By Dr. Surabhi Bhatnagar - Best Astrologer in India, Check your Moon and Ascendant Signs. Aries Horoscope Prediction."
        />
        <meta
          name="twitter:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
      </Helmet>
      <section className="inner-section horoscope-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="horoscope-section pt-4 py-lg-5">
          <div className="container">
            <div className="row">
              <div className="section-head text-center mb-0 col-12">
                <h1 className="pb-2">
                  <span>Weekly</span> Horoscope Prediction
                </h1>
                <h5>9th Dec - 15th Dec</h5>
                <p>Check your Moon and Ascendant Signs</p>
              </div>
              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aries} alt="Astrology Services"></img>
                  </div>
                  <h3>Aries</h3>
                  <p className=" ">
                    This week is very important in terms of family. You will be spending more time with family members especially younger ones and will develop a special bond with them. You will be taking big responsibilities and will show nobility too. You will also plan a trip or get together with relatives to keep all happy. It is advised that doing all this , keep a check on your expenditure as Ketu in the sixth house might lead to unnecessary expenses and overspending. Be careful while completing your work at your workplace. It is likely that you will try to finish in haste in overconfidence and the final result will be not good. Double check before passing on to seniors . But you will have divine help at the nick of time.</p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={taurus} alt="Astrology Services"></img>
                  </div>
                  <h3>Taurus</h3>
                  <p className=" ">
                    This week, due to Jupiter being placed in the first house , you will get good profits even after less hard work to maintain the momentum in finances. The Planetary conjunctions will make sure that your unnecessary expenditure and sudden spends are low and you start saving too. it is likely that you might face some difficulties and disturbances at your workplace which might lead to mental stress. It is advised that you should seek help from seniors and experts to come out of the situation and bring your career to a good position. Make proper plans and strategies to move ahead in your career. This mental stress can disturb your personal life too. Take care of your diet. </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={gemini} alt="Astrology Services"></img>
                  </div>
                  <h3>Gemini</h3>
                  <p className=" ">
                    This week is going to bring many possibilities of earning good money but you need to be alert to comprehend them, otherwise you might not be able to take advantage . Family will play an important role in your life and you will be taking part in many important decisions. Being ambitious and goal oriented will not suffice your hunger to progress. You will constantly yearn for improvements and for that revise your plans and policies. Your results will turn out according to the efforts you are investing. You will feel that the week is highly favourable in the beginning and will motivate you to work harder. You also might plan to spend some on yourself and your family. Relax and rejuvenate with family and friends. </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Blue
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 4
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={Cancer} alt="Astrology Services"></img>
                  </div>
                  <h3>Cancer</h3>
                  <p className=" ">
                    This week, due to Jupiter being placed in the eleventh house of gains, the investments you will make will earn you good profits and extra money much better than usual. You will feel blessed as you will find help and cooperation coming from all sides, especially friends. But it is advised that don't take them for granted and always pay respect by using your words carefully and keeping your behavior congenial. Your mind will be more devoted towards fulfillment of your comforts but it is advised that you should give equal priority to the tasks given to you. You should be concentrating and focusing only on your goals and avoid being emotional. Rahu present in the ninth house may cloud your mind with negativity so be alert.</p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> White
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 2
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={leo} alt="Astrology Services"></img>
                  </div>
                  <h3>Leo</h3>
                  <p className=" ">
                    This week, due to Saturn being placed in the seventh house, will bring a rise in your career. It is advised that you should get a thorough understanding of the work you are assigned. You will be having a very fruitful and result oriented meeting with the seniors which will help in giving a boost to your career. Throughout the week, the presence and influence of many auspicious planets will help you achieve success in your tasks in accordance with your hard work. God has bestowed you with confidence and intellect, so take utmost advantage of your ideas and spend your time on productive work. This week you will be spending good time with your family in happiness.</p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Orange
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 1
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={virgo} alt="Astrology Services"></img>
                  </div>
                  <h3>Virgo</h3>
                  <p className=" ">
                    This week, you will get relief from your stress and ups and downs in career and profession. This period is going to bring some good changes and unexpected events in your life which you have been waiting for a long time. It is also advised that you should be careful too at your workplace as some people might not digest your progress and conspire against you. Avoid making hasty decisions and do not undertake any unnecessary work which can lead to mistakes. It is likely that you will be presented with a new plan and you will be promised benefits of the new agreement. It might be a trap. You will enjoy continued support from the family hence ask for suggestions from them.   </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Blue
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 4
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={libra} alt="Astrology Services"></img>
                  </div>
                  <h3>Libra</h3>
                  <p className=" ">
                    This week, your leadership and administrative skills will be enhanced due to the constant favourable movement of planets. You will be able to prove yourself and a distinct identity and a  respectful image will be created at the workplace which will be beneficial in the future. You are likely to meet someone special at the workplace which will help you gain insights for the future. It is advised that you should invest and also save your money for the future. Favourable planetary conjunctions are indicating profits from the money used in right schemes. Advice from a family member will help you earn extra and also increase your happiness. Responsibilities will increase for you. </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={scorpio} alt="Astrology Services"></img>
                  </div>
                  <h3>Scorpio</h3>
                  <p className=" ">
                    This week, due to Jupiter being placed in the seventh house, you will attain excellent results in terms of the profession such as monetary increment and promotion. You will be able to achieve all of it by putting to use every diplomatic strategy with your discipline and hard work. You will be blessed with excellent words of wisdom from your elders and seniors which will not only guide you but also help you succeed in your life. Immense support from them will make your day. You are advised that you should avoid thinking too much and avoid mental stress. You should put in your best efforts to improve on this habit. Be careful while investing your money in any scheme as it could be a trap to lure you and you lose . </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> White
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 2
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={Sagittarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Sagittarius</h3>
                  <p className=" ">
                    This week, due to improvement in financial condition, you will have an extra edge and you will be spending more on family and its needs. This period is highly favourable in terms of money especially for people in business, as many new and several sources will help you earn good profits. Foreign dealings and business will be successful and you will be rendered help from government departments and officials. It is advised that you should be creative and innovative in your field and also try to learn new skills . Take advice from family especially your partner while making any decision. Rahu in the fourth house can lead to instances of spending unnecessary money and give mental stress later.   </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Red
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 9
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={capricorn} alt="Astrology Services"></img>
                  </div>
                  <h3>Capricorn</h3>
                  <p className=" ">
                    This week will bring happiness, prosperity and peace in your family. You will also plan to visit religious places to pay your gratitude. Rahu being placed in the third house will make you achieve great achievements in your professional life. Presence of many favourable planets will make you emerge as a hardworking, more productive and efficient person at your workplace. Your diplomatic and strategic behavior will help you deal with all situations at your workplace and earn you a good reputation in the eyes of the senior management. Saturn being placed in the second house may lead to some expenses, which will be sudden and unavoidable. Consulting with family will help a lot.  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong>
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aquarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Aquarius</h3>
                  <p className=" ">
                    This week, hard work done by you in the past will pay off and you will get a promotion and appreciation from your colleagues as well as seniors. It is advised that you keep your humility and helpful attitude intact which will make others feel proud of you and will make your distinct image at the workplace. Your efforts will be appreciated and supported by all and you will feel blessed. You might come across some attractive investment opportunities but consider them only after good thought and going through the details thoroughly. This is a good time to have fun with family and friends hence plan an outing with them.  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={pisces} alt="Astrology Services"></img>
                  </div>
                  <h3>Pisces</h3>
                  <p className=" ">
                    This week, your positive attitude will be observed and highly praised by others at the workplace. You will turn your foes into your friends . You will get success with less hard work and will be appreciated by all including your colleagues as well as your senior management. You are advised to keep stress at bay and enjoy your success. Rahu in the first house will help especially traders and businessmen to expand their work in other areas also. This will help you earn favourable results. It is suggested not to invest all money in business at one go. Invest slowly and patiently. Your decisions will improve your relations with people involved in business whether family or partners.     </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <Col xs={12} className="section-head t-head mb-0 my-4">
                {/* <h4><span>What Do Our Clients Say</span> About Us?</h4> */}
                <h4>
                  <span>Our</span> Services
                </h4>
              </Col>

              <div className="col-12">
                <Swiper
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper testimonial-swiper services-swiper pt-5">
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s1} alt="Astrology Services"></img>
                        </div>
                        <h3>Astrology</h3>
                        <p>
                          Astrology, also known as Jyotish, is an ancient and
                          divine knowledge that can see into our past...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/astrology-service"
                            className="button button-2">
                            Read More{" "}
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s2} alt="Vastu Shastra services"></img>
                        </div>
                        <h3>Vastu Shastra</h3>
                        <p>
                          Vastu Shastra is a divine science that we have used
                          since ancient times to plan and build our homes...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/vastu-shastra-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img
                            src={s3}
                            alt="Handwriting and Signature Analysis (Astrology)"></img>
                        </div>
                        <h3>Handwriting & Signature Analysis</h3>
                        <p>"We can judge a person by looking at his...</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/handwriting-signature-analysis"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s4} alt="Tarot Card Reading"></img>
                        </div>
                        <h3>Tarot</h3>
                        <p>
                          Tarot card reading is an ancient technique that was
                          popular in Europe centuries ago and has now spread all
                          ...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a href="/tarot-service" className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s5} alt="Palmistry Services" />
                        </div>
                        <h3>Palmistry</h3>
                        <p>
                          Palmistry is an age old technique in which an
                          astrologer reads and analyzes the palm of the
                          native....
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/palmistry-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s6} alt="Numerology services" />
                        </div>
                        <h3>Numerology</h3>
                        <p>
                          Numerology is the wing of astrology where we consider
                          numbers and predict the past, present and future.
                          Numerology helps people...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/numerology-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s7} alt="Reiki Healing Services" />
                        </div>
                        <h3>Reiki Healing</h3>
                        <p>
                          It is the japanese technique which makes use of hands
                          and invokes energy and blessings from the universe. It
                          is an age-old...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/reikihealing-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
